import { Client } from './Client';
import { Provider } from './Provider';
import { User } from './User';
export interface Society {
    id?: number;
    name: string;
}

export interface SocietyDataForm {
    id?: number;
    rut?: string;
    name?: string;
    fantasy_name?: string;
    logo?: File;

    // first_name_contact: string;
    // last_name_contact: string;
    // second_last_name_contact: string;
    email?: string;
    phone_code?: string;
    phone?: string;
    commune?: string;
    commune_id?: number;
    region?: string;
    region_id?: number;
    country?: string;
    country_id?: number;
    address?: string;
    is_national?: boolean;
    clients?: Client[];
    client_ids?: number[];
    providers?: Provider[];
    provider_ids?: number[];
    
    // is_active: boolean;
    // user_id?: number;
    // user?: Client;
}

export interface SocietyData {
    id?: number;
    rut?: string;
    name: string;
    logo?: File;
    email: string;
    phone_code: string;
    phone: string;
    address: string;
    is_active: boolean;
}


export const defaultSocietyForm: SocietyData = {
    rut: '',
    name: '',
    email: '',
    phone_code: '',
    phone: '',
    address: '',
    is_active: true
};

export interface SocietyFilters {
    id?: number | null;
    name?: string | '';
    rut?: string | '';
    // company_type?: string | '';
    // is_active?: boolean | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}