import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { PurchaseorderDataForm } from '../../../../app/models/PurchaseOrder';
import { Provider } from '../../../../app/models/Provider';
import { Society } from '../../../../app/models/Society';
import { Incoterm } from '../../../../app/models/Incoterm';
import { Tax } from '../../../../app/models/Tax';
import { PaymentType } from '../../../../app/models/PaymentType';
import LazyLoading from '../../../../components/LazyLoading';
import { PaymentCondition } from '../../../../app/models/PaymentCondition';
import { Place } from '../../../../app/models/Place';
import { Currency } from '../../../../app/models/Currency';
import { PaymentMethod } from '../../../../app/models/PaymentMethod';
import { ProviderContact } from '../../../../app/models/ProviderContact';

interface GeneralPurchaseOrderProps {
    purchaseOrderDataForm: PurchaseorderDataForm;
    setPurchaseOrderDataForm: (purchaseOrder: PurchaseorderDataForm) => void;
    societies: Society[];
    providers: Provider[];
    providerContact: ProviderContact[];
    incoterms: Incoterm[];
    paymentMethods: PaymentMethod[];
    currencies: Currency[];
    paymentConditions: PaymentCondition[];
    places: Place[];
    errorFields?: any;
    societyLoading: boolean;
    providerLoading: boolean;
    providerContactLoading:boolean;
}

const PurchaseOrderForm = ({
    purchaseOrderDataForm,
    setPurchaseOrderDataForm,
    societies,
    providers,
    providerContact,
    incoterms,
    paymentMethods,
    currencies,
    paymentConditions,
    places,
    errorFields,
    societyLoading,
    providerLoading,
    providerContactLoading,
}: GeneralPurchaseOrderProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();
    
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setPurchaseOrderDataForm({ ...purchaseOrderDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if(name === 'society_id'){
            setErrors(
                {
                    ...errors,
                    provider_id:''
                }
            )
        }

        setPurchaseOrderDataForm({ ...purchaseOrderDataForm, ...obj });
    };
    
    const handleDestinyChangeSelectTwo = (value: any) => {
        const obj = { 
            'destiny': value.label,
            'place_id': value.value 
        };

        setPurchaseOrderDataForm({ ...purchaseOrderDataForm, ...obj });
    };

    const changeMultipleSelectTwo = (taxesSelect: any) => {
        const obj = { taxes: taxesSelect };
        
        setPurchaseOrderDataForm({
            ...purchaseOrderDataForm,
            ...obj
        });
       
    };


    if (!purchaseOrderDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedad</label>
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={purchaseOrderDataForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('society_id', value.value)
                        }
                        placeholder={'Seleccione sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>
       { societyLoading?
            <LazyLoading height="300" />
        
            :

            <>
                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-form-label">
                            Rut
                        </label>
                        <input
                            name="society_rut"
                            id="society_rut"
                            type="text"
                            className="form-control"
                            value={purchaseOrderDataForm.society_rut}
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">
                            Razón social
                        </label>
                        <input
                            name="society_name"
                            id="society_name"
                            type="text"
                            className="form-control"
                            value={purchaseOrderDataForm.society_name}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">
                            Dirección
                        </label>
                        <input
                            name="society_address"
                            id="society_address"
                            type="text"
                            className="form-control"
                            value={purchaseOrderDataForm.society_address}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedor</label>
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={purchaseOrderDataForm.provider_id}
                        options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('provider_id', value.value)
                        }
                        placeholder={'Seleccione proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>
            </>
        }
        <hr />
            {providerLoading?
            <LazyLoading height="300" />
            :
            <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Rut
                    </label>
                    <input
                        name="provider_rut"
                        id="provider_rut"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_rut}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">
                        Razón social
                    </label>
                    <input
                        name="provider_name"
                        id="provider_name"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_name}
                        disabled={true}
                    />
                </div>
            </div>
           
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                        Dirección
                    </label>
                    <input
                        name="provider_address"
                        id="provider_address"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_address}
                        disabled={true}
                    />
                </div>
            </div>

            {/* agregar loading de contacto de proveedor */}
            <div className="col-md-12">
                <label className="col-form-label">Contactos Proveedor</label>
                <SelectTwo
                    name="provider_contacts_id"
                    id="provider_contacts_id"
                    inputValue={purchaseOrderDataForm.provider_contacts_id}
                    options={SelectTwoMapperOptions(providerContact, 'id', ['name'])}
                    hasError={fieldHasError('provider_contacts_id') !== ''}
                    onChange={(value: any) =>
                        handleChangeSelectTwo('provider_contacts_id', value.value)
                    }
                    placeholder={'Seleccione contacto proveedor'}
                    onFocus={() => onFocusRemove('provider_contacts_id')}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('provider_contacts_id')}
                </div>
            </div>

            {providerContactLoading ? (
                <LazyLoading height="300" />
            ) : (
                <>
                {/* Detalles del Contacto Seleccionado */}
                <div className="mb-2 row">
                    <div className="col-md-6">
                    <label className="col-form-label">Nombre contacto</label>
                    <input
                        name="provider_contacts_name"
                        id="provider_contacts_name"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_contacts_name}
                        disabled={true}
                    />
                    </div>
                    <div className="col-md-6">
                    <label className="col-form-label">Email contacto</label>
                    <input
                        name="provider_contact_email"
                        id="provider_contact_email"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_contacts_email}
                        disabled={true}
                    />
                    </div>
                </div>
                <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Teléfono</label>
                    <input
                        name="provider_phone_contact"
                        id="provider_phone_contact"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_contacts_phone || ''}
                        disabled={true}
                    />
                    </div>
                </div>
                </>
            )}
          
            </>

            }
            
            <hr />
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Forma de pago</label>
                    <SelectTwo
                        name="payment_method_id"
                        id="payment_method_id"
                        inputValue={purchaseOrderDataForm.payment_method_id}
                        options={SelectTwoMapperOptions(paymentMethods, 'id', ['name'])}
                        hasError={fieldHasError('payment_method_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_method_id', value.value)
                        }
                        placeholder={'Selec. forma de pago'}
                        onFocus={() => onFocusRemove('payment_method_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_method_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Moneda</label>
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={purchaseOrderDataForm.currency_id}
                        options={SelectTwoMapperOptions(currencies, 'id', ['name'])}
                        hasError={fieldHasError('currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('currency_id', value.value)
                        }
                        placeholder={'Seleccione moneda'}
                        onFocus={() => onFocusRemove('currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>

            </div>

            <div className="mb-2 row">
                    
                <div className="col-md-6">
                    <label className="col-form-label">Condición de pago</label>
                    <SelectTwo
                        name="payment_condition"
                        id="payment_condition"
                        inputValue={purchaseOrderDataForm.payment_condition}
                        hasError={fieldHasError('payment_condition') !== ''}
                        options={SelectTwoMapperOptions(paymentConditions)}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_condition', value.value)
                        }
                        onFocus={() => onFocusRemove('payment_condition')}
                        placeholder="Seleccione cond. Pago"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_condition')}
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-form-label">Cantidad de días</label>
                    <input
                        name="days_count"
                        id="days_count"
                        type="number"
                        className={`form-control ${fieldHasError('days_count')}`}
                        value={purchaseOrderDataForm.days_count}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('days_count')}
                        placeholder="Días"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('days_count')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Cond. de entrega</label>
                    <SelectTwo
                        name="incoterm_id"
                        id="incoterm_id"
                        inputValue={purchaseOrderDataForm.incoterm_id}
                        options={SelectTwoMapperOptions(incoterms, 'id', ['name'])}
                        hasError={fieldHasError('incoterm_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('incoterm_id', value.value)
                        }
                        placeholder={'Seleccione condiciones de entrega'}
                        onFocus={() => onFocusRemove('incoterm_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('incoterm_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Destino</label>
                    <SelectTwo
                        name="place_id"
                        id="place_id"
                        inputValue={purchaseOrderDataForm.place_id}
                        options={SelectTwoMapperOptions(places, 'id', ['name'])}
                        hasError={fieldHasError('place_id') !== ''}
                        onChange={(value: any) => {
                            handleDestinyChangeSelectTwo(value)
                        }
                        }
                        placeholder={'Seleccione destino'}
                        onFocus={() => onFocusRemove('place_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`place_id`)}
                    </div>
                </div>
            </div>

        </>
    );
};

export default PurchaseOrderForm;
