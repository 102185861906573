import React, { useEffect, useState } from 'react';
import { BusinessNameContactDataForm } from '../../../../app/models/BusinessNameContact';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { ContactType } from '../../../../app/models/ContactType';


type Props = {
    businessNameContactDataForm: BusinessNameContactDataForm;
    setBusinessNameContactDataForm: (client: BusinessNameContactDataForm) => void;
    contactTypes: ContactType[];
    errorFields?: any;
};

const BusinessNameContactForm = ({ businessNameContactDataForm, setBusinessNameContactDataForm, contactTypes, errorFields }: Props) => {
    
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
       
        setBusinessNameContactDataForm({ ...businessNameContactDataForm, [name]: value });
        
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setBusinessNameContactDataForm({ ...businessNameContactDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-form-label">Nombre</label>
                <div className="col-md-12">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={businessNameContactDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese nombre contacto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-form-label">Email</label>
                <div className="col-md-12">
                    <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={businessNameContactDataForm.email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email')}
                        placeholder="Ingrese email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email')}
                    </div>
                </div>
            </div>

            <div className='mb-2 row'>
                <div className="col-md-12">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code"
                                name="phone_code"
                                onChange={handleChange}
                                value={businessNameContactDataForm.phone_code}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option value="">--</option>
                                <option value="+56">+56</option>
                            </select>
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone_code')}
                            </div>
                        </div>

                        <div className="col">
                            <input
                                name="phone"
                                id="phone"
                                type="number"
                                className="form-control"
                                value={businessNameContactDataForm.phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de contacto</label>
                    <SelectTwo
                        name="contact_type_id"
                        id="contact_type_id"
                        inputValue={businessNameContactDataForm.contact_type_id}
                        options={SelectTwoMapperOptions(contactTypes, 'id', ['name'])}
                        hasError={fieldHasError('contact_type_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('contact_type_id', value.value)}
                        placeholder={'Seleccione tipo contacto'}
                        onFocus={() => onFocusRemove('contact_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('contact_type_id')}
                    </div>
                </div>
            </div>

        </>
    );
};

export default BusinessNameContactForm;
