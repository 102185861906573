import React, { useRef } from 'react';

type Props = {
    handleChangeDocumentFile?: (files: any, type: string) => void;
    handleRemoveFile?: (name: string) => void;
    objectFile: any; 
    name: string; 
    onFocus?: (value: any) => void;
    disabled?: boolean; 
};

const InputButtonFile = ({
    handleChangeDocumentFile,
    handleRemoveFile,
    onFocus,
    objectFile,
    name,
    disabled,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        if (!disabled) inputRef.current?.click();
    };

    const download = () => {
        if (!objectFile) {
            console.warn('No hay archivo disponible para descargar.');
            return;
        }

        if (typeof objectFile === 'string' && objectFile !== '') {
            window.open(objectFile, '_blank');
        } else {
            const url = window.URL.createObjectURL(objectFile);
            const a = document.createElement('a');
            const fileName = objectFile.name;
            a.href = url;
            a.download = fileName;
            a.click();
        }
    };

    return (
        <div className="input-group mb-3">
            <button
                onClick={download}
                className={`btn ${
                    objectFile ? 'btn-success' : 'btn-secondary'
                }`}
                style={{
                    cursor: objectFile ? 'pointer' : 'not-allowed',
                    flexGrow: 1,
                }}
                disabled={!objectFile} 
            >
                {disabled ? (
                    <>
                        <i className="bx bx-download me-2" />
                        Descargar
                    </>
                ) : (
                    <i className="bx bx-download" />
                )}
            </button>

            {!disabled && (
                <>
                    <input
                        ref={inputRef}
                        onChange={(e) =>
                            handleChangeDocumentFile?.(e.target.files, name)
                        }
                        className="d-none"
                        type="file"
                        accept=".docx,.xls,.pdf"
                        name={name}
                        id={name}
                    />

                    <button
                        style={{
                            flexGrow: 4, 
                            zIndex: 0,
                        }}
                        onClick={handleUpload}
                        onFocus={onFocus}
                        className={`btn ${
                            objectFile
                                ? 'btn-outline-success mdi mdi-checkbox-marked-circle'
                                : 'btn-outline-secondary'
                        }`}
                    >
                        {objectFile ? 'OK' : 'Cargar Archivo'}
                    </button>

                    {objectFile && handleRemoveFile && (
                        <button
                            onClick={() => handleRemoveFile(name)}
                            className="btn btn-danger"
                        >
                            <i className="mdi mdi-delete-forever" />
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default InputButtonFile;
